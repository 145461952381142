<template>
  <div class="layout-auth">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style lang="scss">
.layout-auth{
  //height: 100vh;
  padding-top: calc(env(safe-area-inset-top) + 25px);
}
</style>

